<!--
 * @Descripttion:调研问卷
 * @version: 
 * @Author: 86131
 * @Date: 2021/3/31 15:19
-->
<template>
  <div class="survey-question">
    <div class="title">{{ title }}</div>
    <div class="main">
      <div class="main-content">
        <div class="main-content-left">
          <div class="qr-code-block">
            <div class="qr-code">
              <qrcode-vue :value="link" :size="150"></qrcode-vue>
            </div>
            <div class="download-img">
              <a-button class="download-button" type="default" @click="handleDownloadQrcode">
                <template #icon><span class="iconfont">&#xe612;</span></template>
                下载图片
              </a-button>
            </div>
          </div>
        </div>
        <div class="main-content-right">
          <div class="question-visit-block">
            <div class="block-top">问卷访问链接：</div>
            <div class="block-bottom">
              <a-input v-model:value="link" style="width: 375px;height: 41px" disabled=""/>
              <a-button class="copy"
                v-clipboard:copy="link"
                v-clipboard:success="onCopy"
                v-clipboard:error="onCopyError"
              >复制</a-button>
              <a-button class="open" @click="handleOpenLink">打开</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import html2canvas from 'html2canvas'

export default {
  name: "SurveyQuestion",
  components: {
    QrcodeVue,
  },
  props: {
    title: String, // 问卷标题
  },
  data() {
    return {
      // qrUrl: 'http://wwww.baidu.com',
      // link: 'http://questionnaire.dev.gobrand.top/#/exercise/1379376662461808640',
      link: '',
    }
  },
  methods: {
    onCopy() {
      this.$message.success('复制成功')
    },
    onCopyError() {
      this.$message.error('复制失败')
    },
    // 打开链接
    handleOpenLink() {
      window.open(this.link, '_blank')
    },
    // 下载二维码
    handleDownloadQrcode() {
      let qrcode = document.querySelector('canvas')
      html2canvas(qrcode).then(canvas => {
        let link = document.createElement("a")
        link.href = canvas.toDataURL()  // 下载链接
        link.download = '二维码.jpg'
        link.style.display = "none" // a标签隐藏
        document.body.appendChild(link)
        link.click()
      })
    },
  },
  created() {
    let baseUrl = process.env.VUE_APP_BASE_WEB_URL
    this.link = `${baseUrl}/#/exercise/${this.$route.params.id}`
  }
}
</script>

<style lang="less" scoped>
.survey-question {
  margin-top: 50px;
  min-height: 881px;
  background: #FFFFFF;
  border-radius: 6px;

  .title {
    width: 100%;
    padding-top: 61px;
    padding-bottom: 72px;
    display: flex;
    font-size: 26px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    //border: 1px solid black;
  }

  .main {
    width: 100%;
    height: 13rem;
    //border: 1px solid black;
    display: flex;
    justify-content: center;

    .main-content {
      width: 100%;
      height: 100%;
      //border: 1px solid black;
      display: flex;
      flex-wrap: nowrap;

      .main-content-left {
        width: 456px;
        padding-left:252px;
        height: 100%;
        border-right: 1px solid #F1F1F1;

        .qr-code-block {
          width: 9rem;
          height: 100%;
          //border: 1px solid black;

          .qr-code {
            height: 75%;
            display: flex;
            justify-content: center;
            align-items: center;
            //border: 1px solid black;
          }
          .download-img {
            height: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
            //border: 1px solid black;
            .download-button {
              width: 140px;
              height: 36px;
              line-height: 36px;
              font-size: 18px;
              background: #00A0E9;
              color: #fff;
            }
            .default {
              border-radius: 2px;
            }
          }
        }
      }
      .main-content-right {
        width: 844px;
        height: 100%;

        .question-visit-block {
          margin-top: 57px;
          margin-left: 40px;
          //border: 1px solid black;

          .block-top {
            margin-bottom: 16px;
            font-size: 20px;
            font-weight: 100;
            //border: 1px solid black;
          }
          .block-bottom {
            .copy {
              height: 41px;
              background: #00A0E9;
              color: #FFFFFF;
              margin-left: 1rem;
              border-radius: 2px;
            }
            .open {
              height: 41px;
              margin-left: 1rem;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
}
</style>