<!--
 * @Descripttion:问卷派发
 * @version: 
 * @Author: 86131
 * @Date: 2021/3/31 15:12
-->
<template>
  <div class="dispatch-question">
    <div v-if="!ifRelease">
      <question-tips 
        v-if="allowValidQuestionnaireNum && validQuestionnaireNum >= validQuestionnaireNumSetting"
        :validQuestionnaireNum="validQuestionnaireNumSetting"/>
      <question-to-be-publish
        v-else
        @handleRelease="handleRelease"
      ></question-to-be-publish>
      
    </div>
    <survey-question v-else :title="title"></survey-question>
  </div>
</template>

<script>
import QuestionToBePublish from '@/views/detail/dispatchQuestion/components/QuestionToBePublish'
import SurveyQuestion from '@/views/detail/dispatchQuestion/components/SurveyQuestion'
import QuestionTips from '@/views/detail/dispatchQuestion/components/QuestionTips'
import { getQuestionnaireList, getQuestionnaireSettingById } from '@/api/questionnaire'

export default {
  name: 'DispatchQuestion',
  components: {
    QuestionToBePublish,
    SurveyQuestion,
    QuestionTips,
  },
  data() {
    return {
      ifRelease: false, // 问卷是否已经暂停派发
      title: '', // 问卷名称
      allowValidQuestionnaireNum: false, // 是否允许问卷数量
      validQuestionnaireNumSetting: 0, // 允许问卷数量
      validQuestionnaireNum: 0, // 实际的问卷数量
    }
  },
  methods: {
    handleRelease() {
      this.getQuestionInfo()
    },
    async getQuestionInfo() {
      let params = {
        id: this.$route.params.id,
      }
      const res = await getQuestionnaireList(params)
      if (res.status === 200 && res.data?.code === '00000') {
        let content = res.data.data.content
        let question = content[0]
        this.title = question.title
        this.ifRelease = question.ifRelease
        this.validQuestionnaireNum = question.validQuestionnaireNum
        if (!this.ifRelease) {
          const result = await getQuestionnaireSettingById(params.id)
          if (res.status === 200 && res.data?.code === '00000') {
            const data = result.data.data
            this.allowValidQuestionnaireNum = data.allowValidQuestionnaireNum
            this.validQuestionnaireNumSetting = data.validQuestionnaireNum
          }
        }
        // console.log('question:', question)
      }
    },
  },
  created() {
    this.getQuestionInfo()
  }
}
</script>

<style lang="less" scoped>
.dispatch-question {
  width: 1300px;
  margin: 0 auto;
  margin-top: 50px;
}
</style>