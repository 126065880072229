<!--
 * @Description: 问卷派发提醒
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-19 11:27:35
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-07 17:14:44
-->
<template>
  <div class="question-tips">
    <div class="title">问卷待发布</div>
    <div class="main">
      <div class="main-top">
        <div>
          <p>此问卷已达到 问卷设置的有效问卷数量：{{ validQuestionnaireNum }}</p>
          <p>如果想要继续派发问卷，请编辑问卷，调整有效问卷数量。</p>
        </div>
      </div>
      <div class="main-bottom">
        <a-button class="release" @click="handleEditQuestion">
          编辑问卷
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionTips',
  props: {
    validQuestionnaireNum: Number
  },
  methods: {
    handleEditQuestion() {
      const id = this.$route.params.id
      this.$router.push(`/edit/questionEdit/${id}?isKeepAnswerAndEdit=true`)
    }
  }
}
</script>

<style lang="less" scoped>
.question-tips {
  margin-top: 2rem;
  width: 1300px;
  height: 881px;
  background: #FFFFFF;
  border: 1px solid #fff;
  border-radius: 6px;
  .title {
    margin-top: 61px;
    width: 100%;
    height: 2rem;
    font-weight: bold;
    font-size: 26px;
    display: flex;
    justify-content: center;
  }
  .main {
    width: 100%;
    height: 8rem;

    .main-top {
      width: 100%;
      margin: 48px 0 32px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 100;
      p {
        padding: 5px 0;
      }
    }
    .main-bottom {
      width: 100%;
      height: 35%;
      display: flex;
      justify-content: center;
      align-items: center;

      .release {
        width: 140px;
        height: 36px;
        line-height: 30px;
        font-size: 18px;
        background: #00A0E9;
        color: #fff;
        border-radius: 2px;
      }
    }
  }
}
</style>