<!--
 * @Descripttion:问卷待发布（提醒页）
 * @version: 
 * @Author: 86131
 * @Date: 2021/3/31 15:15
-->
<template>
  <div class="question">
    <div class="title">问卷待发布</div>
    <div class="main">
      <div class="main-top">此问卷处于未发布状态，如果问卷已经编辑完成，请先发布问卷。</div>
      <div class="main-bottom"><a-button class="release" @click="handleReleaseQuestion">发布问卷</a-button></div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import {releaseQuestionnaire} from '@/api/questionnaire'

export default {
  name: "QuestionToBePublish",
  props: {
  },
  methods: {
    async handleReleaseQuestion() {
      let questionId = this.$route.params.id
      const res = await releaseQuestionnaire(questionId)
      if (res.status === 200 && res.data?.code === '00000') {
        this.$emit('handleRelease')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.question {
  width: 1300px;
  height: 881px;
  background: #FFFFFF;
  border: 1px solid #fff;
  border-radius: 6px;
  .title {
    margin-top: 61px;
    width: 100%;
    height: 2rem;
    font-weight: bold;
    font-size: 26px;
    display: flex;
    justify-content: center;
  }

  .main {
    width: 100%;
    height: 8rem;

    .main-top {
      width: 100%;
      margin: 48px 0 32px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 100;
    }
    .main-bottom {
      width: 100%;
      height: 35%;
      display: flex;
      justify-content: center;
      align-items: center;

      .release {
        width: 140px;
        height: 36px;
        line-height: 30px;
        font-size: 18px;
        background: #00A0E9;
        color: #fff;
        border-radius: 2px;
      }
    }
  }
}
</style>